import React from "react";
import logo from '../assets/logo/logo.png';
import { Link } from 'react-router-dom';
import 'boxicons';
function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-info">
                <Link to="/">
                  <img src={logo} className="img-fluid" alt="logo" />
                </Link>
                <p>
                  Office 12, Diplomat Office Towers <br />
                  Diplomatic Area, Kingdom of Bahrain<br /><br />
                  <strong>Phone:</strong> (973) 17534123<br />
                  <strong>Email:</strong>  aes@aesbah.com<br />
                </p>

              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer-links" >
              <div>
                <h4>Quick Links</h4>
                <ul>

                  <li><i className="bi bi-chevron-right"></i> <Link to="/">Home</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/about">About us</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/expertise">Expertise</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/projects">Projects</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/contact-us">Contact</Link></li>
                </ul>

              </div>

            </div>

            <div className="col-lg-3 col-md-6 footer-links" >
              <div>
                <h4>Our Expertise</h4>
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/expertise/1">Project Management</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/expertise/2">Mechanical and Electrical Engineering</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/expertise/3">Civil and Structural Engineering</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/expertise/4">Architecture and Interior Design</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/expertise/6"> Cadastral and Land Surveying</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/expertise/7"> Quantity Surveying and Cost Engineering</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <Link to="/expertise/8">Marine Engineering</Link></li>
                </ul>

              </div>

            </div>

            <div className="col-lg-3 col-md-6 footer-newsletter">
              <h4>Follow Us</h4>
              <p>Visit us on out social media.</p>
              <div className="social-links mt-3">

                <a href="https://www.instagram.com/aes.bah/" class="instagram" target="_blank" rel="noreferrer">
                  <box-icon color="white" type="logo" name="instagram"></box-icon>
                </a>

                <a href="https://www.linkedin.com/company/ansari-engineering-services" className="linkedin" target="_blank" rel="noreferrer">
                  <box-icon color="white" type="logo" name="linkedin"></box-icon>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
