import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo/logo_full.png';
 import { useLocation } from "react-router-dom";


const Header = () => {

  const [isActive, setIsactive] = useState(false); // eslint-disable-line no-unused-vars

  function logit() {
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }
    //onscroll add header class
    let selectHeader = document.getElementById("header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }
  }

  useEffect(() => {
   isActive && openMenu();
    function watchScroll() {
      window.addEventListener("load", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("load", logit);
    };
  });

  const openMenu = () => {
      let selectNav = document.getElementById("mobile-nav-toggle");
      document.getElementById("navbar").classList.toggle('navbar-mobile')
      selectNav.classList.toggle('bi-list')
      selectNav.classList.toggle('bi-x')
  }

  const pathname = useLocation().pathname;

  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center justify-content-between">
        <Link to="/">
            <img src={logo} className="img-fluid" alt='Logo'/>
        </Link>
        <nav id="navbar" className='navbar' >
          <ul>
            <li>
              <Link to="/expertise"  className={`${pathname === '/expertise' ? 'active' : ''}` }>EXPERTISE</Link>
            </li>
            <li>
              <Link  to="/projects" className={`${pathname === '/projects' ? 'active' : ''}`} >PROJECTS</Link>
            </li>
            <li>
              <Link to="/about" className={`${pathname === '/about' ? 'active' : ''}`}>ABOUT US</Link>
            </li>
            <li>
              <Link to="/contact-us" className={`${pathname === '/contact-us' ? 'active' : ''}`}>CONTACT US</Link>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" id="mobile-nav-toggle"  onClick={openMenu}></i>
        </nav>
      </div>
    </header>
  );

}


export default Header;
